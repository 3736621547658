import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "pa-18"
};
const _hoisted_2 = {
  class: "pa-18"
};
import { router } from '@/router';
import ChangeLoginPwd from '@/components/ChangeLoginPwd/ChangeLoginPwd.vue';
import ChangePayPwd from '@/components/ChangePayPwd/ChangePayPwd.vue';
import { ref } from 'vue';
export default {
  __name: 'changePwd',
  setup(__props) {
    const active = ref(0);
    const back = () => {
      router.back();
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_tab = _resolveComponent("van-tab");
      const _component_van_tabs = _resolveComponent("van-tabs");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
        "left-arrow": "",
        title: _ctx.$t('change_password'),
        placeholder: "",
        fixed: "",
        onClickLeft: back
      }, null, 8, ["title"]), _createVNode(_component_van_tabs, {
        active: active.value,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => active.value = $event),
        color: "#FC72FF",
        sticky: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_van_tab, {
          title: _ctx.$t('login_password')
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(ChangeLoginPwd)])]),
          _: 1
        }, 8, ["title"]), _createVNode(_component_van_tab, {
          title: _ctx.$t('transaction_password')
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(ChangePayPwd)])]),
          _: 1
        }, 8, ["title"])]),
        _: 1
      }, 8, ["active"])], 64);
    };
  }
};